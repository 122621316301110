
import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { TOrderByDirection } from '@th-common/components/material/MatTable/types/table-order-by';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import {
  IVideoArchiveFiltersFormValues,
  IVideoArchiveItem,
  IVideoArchiveSearchRequest,
  TVideoArchiveFiltered,
} from '@th-common/interfaces/video-archive.interface';
import dayjs from 'dayjs';

import { api } from './api';

function convertFormValueToSearchRequestFilters(formValues: IVideoArchiveFiltersFormValues): TVideoArchiveFiltered {
  return {
    userKey: formValues.userKey === 'All' ? null : formValues.userKey,
    storageType: formValues.storageType === 'All' ? null : formValues.storageType,
    downloadJobType: formValues.downloadJobType === 'All' ? null : formValues.downloadJobType,
  };
}

export interface IVideoArchiveState {
  videoArchiveList: IVideoArchiveItem[];
  dataListLoading: boolean;
  searchRequest: IVideoArchiveSearchRequest;
  pagination: Omit<IPagedResult<IVideoArchiveItem>, 'items'>;
  lastTimeUpdated: string;
}

export const defaultFilters: TVideoArchiveFiltered = {
  userKey: null,
  storageType: null,
  downloadJobType: null,
};

const initialState: IVideoArchiveState = {
  videoArchiveList: [],
  dataListLoading: false,
  searchRequest: {
    page: 1,
    pageSize: 36,
    searches: [],
    searchAsOneWord: true,
    orderBy: [
      {
        fieldName: 'id',
        ascending: false,
      },
    ],
    ...defaultFilters,
  },
  pagination: {
    page: 1,
    pageCount: 0,
    totalCount: 0,
  },
  lastTimeUpdated: dayjs().toISOString(),
};

export const slice = createSlice({
  name: 'videoArchive',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
      lastTimeUpdated: dayjs().toISOString(),
    }),
    setDataListLoading: (state, { payload }: { payload: boolean }) => {
      state.dataListLoading = payload;
    },
    search: (state, { payload }) => {
      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        searches: payload ? [payload] : [],
      };
    },
    formFilter: (state, { payload }) => {
      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        ...convertFormValueToSearchRequestFilters(payload),
      };
    },
    setPage: (state, { payload }: { payload: number }) => {
      state.searchRequest.page = payload;
    },
    setOrderBy: (state, { payload: [fieldName, direction] }: { payload: [string, TOrderByDirection] }) => {
      state.searchRequest = {
        ...state.searchRequest,
        orderBy: state.searchRequest.orderBy.length === 0 || direction ? [
          {
            fieldName,
            ascending: direction === 'asc',
          },
        ] : [],
      };
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IVideoArchiveState>) => {
    builder
      .addMatcher(api.endpoints.getVideoArchiveList.matchFulfilled, (state, { payload }) => {
        const { items, ...pagination } = payload;
        state.videoArchiveList = items || [];
        state.pagination = pagination;
        state.lastTimeUpdated = dayjs().toISOString();
      });
  },
});

export default slice.reducer;
