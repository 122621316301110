import { TReduxTag } from '@th-common/enums/redux-tags.enum';
import { IMyDeviceName } from '@th-common/interfaces/device';
import { IDeviceGroup, IDeviceGroupDetails } from '@th-common/interfaces/device-group';
import {
  IAddDeviceGroupsRqstData,
  IEditDeviceGroupRqstData,
} from '@th-common/interfaces/manage-device-groups.interface';
import { apiRoot } from '@th-common/store/api';

export const deviceGroupApi = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getDeviceGroups: builder.query<IDeviceGroup[], void>({
      query: () => 'devicegroup/mine',
      providesTags: [
        TReduxTag.DeviceGroups,
      ],
    }),
    getDeviceGroupDetails: builder.query<IDeviceGroupDetails, number>({
      query: (deviceGroupId) => `devicegroup/${deviceGroupId}`,
    }),
    getDeviceGroupDeviceNames: builder.query<IMyDeviceName[], number>({
      query: (deviceGroupId) => `devicegroup/${deviceGroupId}/device-names`,
      providesTags: [
        TReduxTag.DeviceGroupNames,
      ],
    }),
    deleteDeviceGroup: builder.mutation<void, number>({
      query: (deviceGroupId) => ({
        url: `devicegroup/${deviceGroupId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        TReduxTag.DeviceGroups,
        TReduxTag.AllMyDevices,
      ],
    }),
    addDeviceGroup: builder.mutation<IDeviceGroup, IAddDeviceGroupsRqstData>({
      query: (data: IAddDeviceGroupsRqstData) => ({
        url: 'devicegroup',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: [
        TReduxTag.DeviceGroups,
      ],
    }),
    editDeviceGroup: builder.mutation<IDeviceGroup, IEditDeviceGroupRqstData>({
      query: (data: IEditDeviceGroupRqstData) => ({
        url: 'devicegroup',
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: [
        TReduxTag.DeviceGroups,
        TReduxTag.DeviceGroupNames,
        TReduxTag.AllMyDevices,
      ],
    }),
  }),
});
