import {
  deviceFilterKindToFilterValue,
  deviceFilterTitleToKind,
  TDeviceFilterKind, TDeviceFilterValue,
} from '@th-common/enums/device-filter-kind';

export interface IDeviceGroupFilter {
  deviceFilterKind: TDeviceFilterKind;
  deviceGroupId: number | null;
}

export function convertToDeviceGroupFilter(deviceGroupId: string | number): IDeviceGroupFilter {
  const isDeviceGroup = !['All', 'Online', 'New'].includes(deviceGroupId as string);
  return {
    deviceFilterKind: !isDeviceGroup ? deviceFilterTitleToKind[deviceGroupId] : TDeviceFilterKind.DeviceGroup,
    deviceGroupId: !isDeviceGroup ? null : deviceGroupId as number,
  };
}

export function getDeviceGroupId(deviceFilterKind: TDeviceFilterKind, deviceGroupId: number | null): TDeviceFilterValue {
  return [
    TDeviceFilterKind.All,
    TDeviceFilterKind.Online,
    TDeviceFilterKind.New,
  ].includes(deviceFilterKind)
    ? deviceFilterKindToFilterValue[deviceFilterKind as (TDeviceFilterKind.All | TDeviceFilterKind.Online | TDeviceFilterKind.New)]
    : deviceGroupId as number;
}
