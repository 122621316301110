import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { databaseApi } from '@th-common/api/database.api';
import { TOrderByDirection } from '@th-common/components/material/MatTable/types/table-order-by';
import { TDeviceFilterKind } from '@th-common/enums/device-filter-kind';
import { IDeviceStatusListNotification } from '@th-common/enums/signalr-methods.enum';
import { TCombinedFiltersType } from '@th-common/enums/status/operational-status.enum';
import {
  IDatabaseDevice,
  IVehicleDatabaseFiltersFormValues,
} from '@th-common/interfaces/database.interface';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import {
  TVideoPlaybackDevicesFiltered,
  TVideoPlaybackDevicesSearchRequest,
} from '@th-common/interfaces/video-playback-devices.interface';
import { convertToDeviceGroupFilter } from '@th-common/utils/convert-to-group-filter';
import dayjs from 'dayjs';

export const defaultFilters: TVideoPlaybackDevicesFiltered = {
  deviceGroupId: null,
  deviceFilterKind: TDeviceFilterKind.All,
};

function convertFormValueToSearchRequestFilters(formValues: IVehicleDatabaseFiltersFormValues): TVideoPlaybackDevicesFiltered {
  return {
    ...(convertToDeviceGroupFilter(formValues.deviceGroupId)),
  };
}

export interface IVideoPlaybackDevicesState {
  devicesList: IDatabaseDevice[];
  devicesLoading: boolean;
  combinedFilter: TCombinedFiltersType;
  searchRequest: TVideoPlaybackDevicesSearchRequest;
  pagination: Omit<IPagedResult<IDatabaseDevice>, 'items'>;
  lastTimeUpdated: string;
}

const initialState: IVideoPlaybackDevicesState = {
  devicesList: [],
  devicesLoading: false,
  combinedFilter: 'All',
  searchRequest: {
    page: 1,
    pageSize: 36,
    searches: [],
    searchAsOneWord: true,
    orderBy: [
      {
        fieldName: 'id',
        ascending: false,
      },
    ],
    ...defaultFilters,
  },
  pagination: {
    page: 1,
    pageCount: 0,
    totalCount: 0,
  },
  lastTimeUpdated: dayjs().toISOString(),
};

export const slice = createSlice({
  name: 'videoPlaybackDevices',
  initialState,
  reducers: {
    reset: () => ({
      ...initialState,
      lastTimeUpdated: dayjs().toISOString(),
    }),
    setDevicesLoading: (state, { payload }: { payload: boolean }) => {
      state.devicesLoading = payload;
    },
    search: (state, { payload }) => {
      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        searches: payload ? [payload] : [],
      };
    },
    formFilter: (state, { payload }) => {
      state.combinedFilter = payload.combinedFilter;
      state.searchRequest = {
        ...state.searchRequest,
        page: 1,
        ...convertFormValueToSearchRequestFilters(payload),
      };
    },
    setPage: (state, { payload }: { payload: number }) => {
      state.searchRequest.page = payload;
    },
    setOrderBy: (state, { payload: [fieldName, direction] }: { payload: [string, TOrderByDirection] }) => {
      state.searchRequest = {
        ...state.searchRequest,
        orderBy: state.searchRequest.orderBy.length === 0 || direction ? [
          {
            fieldName,
            ascending: direction === 'asc',
          },
        ] : [],
      };
    },
    signalRUpdateVideoPlaybackDeviceStatuses: (state, { payload }: { payload: IDeviceStatusListNotification[] }) => {
      if (!payload?.length) {
        return;
      }
      const deviceStatusListMap = new Map<number, IDeviceStatusListNotification>();

      payload.forEach((item) => {
        deviceStatusListMap.set(item.deviceId, item);
      });

      state.devicesList = state.devicesList.map((device) => {
        const deviceStatus = deviceStatusListMap.get(device.id);
        if (deviceStatus) {
          return {
            ...device,
            discoveryStatus: deviceStatus.discoveryStatus,
            deviceCommunicationScope: deviceStatus.deviceCommunicationScope,
          };
        }

        return device;
      });
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IVideoPlaybackDevicesState>) => {
    builder
      .addMatcher(databaseApi.endpoints.getVideoPlaybackDevices.matchFulfilled, (state, { payload }) => {
        const { items, ...pagination } = payload;
        state.devicesList = items || [];
        state.pagination = pagination;
        state.lastTimeUpdated = dayjs().toISOString();
      });
  },
});

export const { signalRUpdateVideoPlaybackDeviceStatuses } = slice.actions;
export default slice.reducer;
