import { TReduxTag } from '@th-common/enums/redux-tags.enum';
import { IMyDeviceName, IMyDeviceNamesSearchRequest } from '@th-common/interfaces/device';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { IAddUserRqstData, IEditUserRqstData, IUser } from '@th-common/interfaces/user-groups/user-groups';
import { apiRoot } from '@th-common/store/api';

export const userApi = apiRoot.enhanceEndpoints({}).injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getMyDeviceNames: builder.query<IPagedResult<IMyDeviceName>, IMyDeviceNamesSearchRequest>({
      query: (body) => {
        return {
          url: 'user/my-device-names/all',
          method: 'POST',
          body,
        };
      },
    }),
    getAllMyDevices: builder.query<IMyDeviceName[], void>({
      query: () => 'user/all-my-devices',
      providesTags: [
        TReduxTag.AllMyDevices,
      ],
    }),
    addUser: builder.mutation<IUser, IAddUserRqstData>({
      query: (user) => ({
        url: 'user',
        method: 'POST',
        body: user,
      }),
      invalidatesTags: [
        TReduxTag.UserGroups,
      ],
    }),
    editUser: builder.mutation<IUser, IEditUserRqstData>({
      query: (user) => ({
        url: 'user',
        method: 'PUT',
        body: user,
      }),
      invalidatesTags: [
        TReduxTag.UserGroups,
      ],
    }),
    deleteUser: builder.mutation<void, number>({
      query: (userId) => ({
        url: `user/${userId}`,
        method: 'DELETE',
      }),
      invalidatesTags: [
        TReduxTag.UserGroups,
      ],
    }),
  }),
});
