import { createSelector } from '@reduxjs/toolkit';
import {
  IDatabaseDevice,
} from '@th-common/interfaces/database.interface';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import {
  TVideoPlaybackDevicesFiltered, TVideoPlaybackDevicesFormValues, TVideoPlaybackDevicesSearchRequest,
} from '@th-common/interfaces/video-playback-devices.interface';
import { getDeviceGroupId } from '@th-common/utils/convert-to-group-filter';

import { RootState } from '../store';

import { defaultFilters } from './slice';

function convertFilterToFormValues(filters: TVideoPlaybackDevicesFiltered, combinedFilter?: string): TVideoPlaybackDevicesFormValues {
  return {
    deviceGroupId: getDeviceGroupId(filters.deviceFilterKind, filters.deviceGroupId),
    combinedFilter: !combinedFilter ? 'All' : combinedFilter,
  };
}

export const selectors = {
  devicesList: (state: RootState): IDatabaseDevice[] => state.videoPlaybackDevices.devicesList,
  searchRequest: (state: RootState): TVideoPlaybackDevicesSearchRequest => state.videoPlaybackDevices.searchRequest,
  searchString: (state: RootState): string => state.videoPlaybackDevices.searchRequest.searches[0] || '',
  lastTimeUpdated: (state: RootState): string => state.videoPlaybackDevices.lastTimeUpdated,
  devicesLoading: (state: RootState): boolean => state.videoPlaybackDevices.devicesLoading,
  pagination: (state: RootState): Omit<IPagedResult<IDatabaseDevice>, 'items'> => state.videoPlaybackDevices.pagination,
  pageSize: (state: RootState): number => state.videoPlaybackDevices.searchRequest.pageSize,
  defaultFilters: createSelector(
    (): TVideoPlaybackDevicesFiltered => defaultFilters,
    (filters: TVideoPlaybackDevicesFiltered): TVideoPlaybackDevicesFormValues => convertFilterToFormValues(filters),
  ),
  currentFilters: createSelector(
    (state: RootState) => state.videoPlaybackDevices.searchRequest,
    (state: RootState) => state.vehicleDatabase.combinedFilter,
    (searchRequest: TVideoPlaybackDevicesSearchRequest, combinedFilter: string): TVideoPlaybackDevicesFormValues => {
      return convertFilterToFormValues(searchRequest, combinedFilter);
    },
  ),
};
