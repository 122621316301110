import { createSelector } from '@reduxjs/toolkit';
import { IMyDeviceName } from '@th-common/interfaces/device';
import { IDeviceGroup } from '@th-common/interfaces/device-group';

import { RootState } from '../store';

export const selectors = {
  deviceGroups: (state: RootState): IDeviceGroup[] => state.manageDeviceGroups.deviceGroups,
  selectedDeviceGroup: (state: RootState): IDeviceGroup | null => state.manageDeviceGroups.selectedDeviceGroup,
  selectedDeviceGroupId: (state: RootState): number | null => state.manageDeviceGroups.selectedDeviceGroup?.id ?? null,
  isGlobal: (state: RootState): boolean => state.manageDeviceGroups.isGlobal,
  isConfigGroup: (state: RootState): boolean => state.manageDeviceGroups.isConfigGroup,
  groupDevices: (state: RootState): IMyDeviceName[] => state.manageDeviceGroups.groupDevices,
  selectedGroupDevices: (state: RootState): IMyDeviceName[] => state.manageDeviceGroups.selectedGroupDevices,
  availableDevicesSearchValue: (state: RootState): string => state.manageDeviceGroups.availableDevicesSearchValue,
  availableDevices: createSelector(
    (state: RootState) => state.manageDeviceGroups.groupDevices,
    (state: RootState) => state.manageDeviceGroups.availableDevices,
    (state: RootState) => state.manageDeviceGroups.availableDevicesSearchValue,
    (
      groupDevices: IMyDeviceName[],
      availableDevices: IMyDeviceName[],
      availableDevicesSearchValue: string,
    ): IMyDeviceName[] => {
      const groupDeviceIds = new Set(groupDevices.map(device => device.id));
      return availableDevices.filter(device => !groupDeviceIds.has(device.id)
        && device.name.toLowerCase().includes(availableDevicesSearchValue.trim().toLowerCase()));
    },
  ),
  selectedAvailableDevices: (state: RootState): IMyDeviceName[] => state.manageDeviceGroups.selectedAvailableDevices,
  hasChanges: (state: RootState): boolean => state.manageDeviceGroups.hasChanges,
};
