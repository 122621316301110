
import { createSelector } from '@reduxjs/toolkit';
import {
  TDeviceFilterValue,
} from '@th-common/enums/device-filter-kind';
import {
  IDevice,
  IDeviceDetails,
  IDeviceFilters,
  IDeviceFiltersFormValues,
  IDeviceSearchRequest,
  TDevicesFiltered,
} from '@th-common/interfaces/device';
import { IPagedResult } from '@th-common/interfaces/paged-result';
import { getDeviceGroupId } from '@th-common/utils/convert-to-group-filter';

import { RootState } from '../store';

import { defaultFilters } from './slice';

function convertCombinedFilterToFormValues(filters: TDevicesFiltered, combinedFilter?: string): IDeviceFiltersFormValues {
  return {
    deviceGroupId: getDeviceGroupId(filters.deviceFilterKind, filters.deviceGroupId),
    firmwareRevision: filters.firmwareRevision === null ? 'All' : filters.firmwareRevision,
    // orderByDirection: filters.orderBy[0].ascending ? 'asc' : 'desc',
    combinedFilter: !combinedFilter ? 'All' : combinedFilter,
    type: filters.type === null ? 'All' : filters.type,
  };
}

export type TDeviceFilters = Omit<IDeviceFilters, 'deviceFilterKind' | 'deviceGroupId' | 'firmwareRevision'>
& { deviceGroupId: TDeviceFilterValue; firmwareRevision: string; orderByDirection: 'asc' | 'desc' };

export const selectors = {
  device: (state: RootState): IDevice[] => state.fleet.devices,
  lastTimeUpdated: (state: RootState): string => state.fleet.lastTimeUpdated,
  defaultFilters: createSelector(
    () => defaultFilters,
    (filters: TDevicesFiltered): IDeviceFiltersFormValues => convertCombinedFilterToFormValues(filters),
  ),
  currentFilters: createSelector(
    (state: RootState) => state.fleet.searchRequest,
    (state: RootState) => state.fleet.combinedFilter,
    (searchRequest: IDeviceSearchRequest, combinedFilter: string): IDeviceFiltersFormValues => (
      convertCombinedFilterToFormValues(searchRequest, combinedFilter)
    ),
  ),
  searchRequest: (state: RootState): IDeviceSearchRequest => state.fleet.searchRequest,
  getDevicesListLoading: (state: RootState): boolean => state.fleet.devicesListLoading,
  searchString: (state: RootState): string => state.fleet.searchRequest.searches[0] || '',
  activeDeviceIndex: (state: RootState): number | null => state.fleet.activeDeviceIndex,
  activeDeviceId: (state: RootState): number | null => state.fleet.activeDeviceId,
  activeDevice: (state: RootState): IDeviceDetails | null => state.fleet.activeDevice,
  pagination: (state: RootState): Omit<IPagedResult<IDevice>, 'items'> => state.fleet.pagination,
  pageSize: (state: RootState): number => state.fleet.searchRequest.pageSize,
  activeStatusId: (state: RootState): number | null => state.fleet.activeStatusId,
  navigationInProgress: (state: RootState): boolean => state.fleet.navigationInProgress,
};
