import { api } from './api';
import { selectors } from './selectors';
import { slice } from './slice';

interface IVideoPlaybackDevicesStore {
  actions: typeof slice.actions;
  selectors: typeof selectors;
  api: typeof api;
}

const videoPlaybackDevicesStore: IVideoPlaybackDevicesStore = {
  actions: slice.actions,
  selectors,
  api,
};

export { videoPlaybackDevicesStore };
