import { ActionReducerMapBuilder, createSlice } from '@reduxjs/toolkit';
import { deviceGroupApi } from '@th-common/api/devicegroup.api';
import { userApi } from '@th-common/api/user.api';
import { IMyDeviceName } from '@th-common/interfaces/device';
import { IDeviceGroup } from '@th-common/interfaces/device-group';
import dayjs from 'dayjs';

export interface IManageDeviceGroupsState {
  deviceGroups: IDeviceGroup[];
  selectedDeviceGroup: IDeviceGroup | null;
  addedGroup: IDeviceGroup | null;
  isGlobal: boolean;
  isConfigGroup: boolean;
  groupDevices: IMyDeviceName[];
  selectedGroupDevices: IMyDeviceName[];
  availableDevicesSearchValue: string;
  availableDevicesLastTimeUpdated: string;
  availableDevices: IMyDeviceName[];
  selectedAvailableDevices: IMyDeviceName[];
  hasChanges: boolean;
}

const initialState: IManageDeviceGroupsState = {
  deviceGroups: [],
  selectedDeviceGroup: null,
  addedGroup: null,
  isGlobal: false,
  isConfigGroup: false,
  groupDevices: [],
  selectedGroupDevices: [],
  availableDevicesSearchValue: '',
  availableDevicesLastTimeUpdated: dayjs().toISOString(),
  availableDevices: [],
  selectedAvailableDevices: [],
  hasChanges: false,
};

function getSelectedDeviceGroup(state: IManageDeviceGroupsState, id: number): IDeviceGroup | null {
  const selectedDeviceGroup = state.deviceGroups.find((deviceGroup) => deviceGroup.id === id);

  return selectedDeviceGroup ?? null;
}

export const slice = createSlice({
  name: 'manage-device-groups',
  initialState,
  reducers: {
    reset: () => initialState,
    setSelectedDeviceGroup: (state, { payload }: { payload: number }) => {
      const selectedDeviceGroup = getSelectedDeviceGroup(state, payload);
      state.selectedDeviceGroup = selectedDeviceGroup;
      state.selectedAvailableDevices = [];
      state.selectedGroupDevices = [];
      state.hasChanges = false;
      state.isGlobal = selectedDeviceGroup?.isGlobal ?? false;
      state.isConfigGroup = selectedDeviceGroup?.isConfigGroup ?? false;
    },
    setAddedGroup: (state, { payload }: { payload: IDeviceGroup }) => {
      state.addedGroup = payload ?? null;
    },
    setIsGlobal: (state, { payload }: { payload: boolean }) => {
      if (!payload && state.isConfigGroup) {
        return;
      }
      state.isGlobal = payload;
      state.hasChanges = true;
    },
    setIsConfigGroup: (state, { payload }: { payload: boolean }) => {
      state.isConfigGroup = payload;
      state.hasChanges = true;

      if (payload && !state.isGlobal) {
        state.isGlobal = true;
      }
    },
    setSelectedGroupDevices: (state, { payload }: { payload: IMyDeviceName[] }) => {
      state.selectedGroupDevices = payload;
    },
    searchAvailableDevices: (state, { payload }: { payload: string }) => {
      state.availableDevicesSearchValue = payload;
      state.selectedAvailableDevices = [];
    },
    setSelectedAvailableDevices: (state, { payload }: { payload: IMyDeviceName[] }) => {
      state.selectedAvailableDevices = payload;
    },
    addSelectedAvailableDeviceToGroup: (state) => {
      state.groupDevices = [...state.groupDevices, ...state.selectedAvailableDevices];
      state.selectedAvailableDevices = [];
      state.hasChanges = true;
    },
    addDeviceToGroup: (state, { payload }: { payload: IMyDeviceName }) => {
      state.groupDevices = [...state.groupDevices, payload];
      state.selectedAvailableDevices = state.selectedAvailableDevices.filter(({ id }) => id !== payload.id);
      state.hasChanges = true;
    },
    removeDeviceFromGroup: (state, { payload }: { payload: IMyDeviceName }) => {
      state.groupDevices = state.groupDevices.filter(({ id }) => id !== payload.id);
      state.selectedGroupDevices = state.selectedGroupDevices.filter(({ id }) => id !== payload.id);
      state.hasChanges = true;
    },
    removeSelectedGroupDevicesFromGroup: (state) => {
      const selectedGroupDeviceIds = new Set(state.selectedGroupDevices.map(device => device.id));
      state.groupDevices = state.groupDevices.filter(device => !selectedGroupDeviceIds.has(device.id));
      state.selectedGroupDevices = [];
      state.hasChanges = true;
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<IManageDeviceGroupsState>) => {
    builder
      .addMatcher(
        deviceGroupApi.endpoints.getDeviceGroups.matchFulfilled,
        (state, { payload }: { payload: IDeviceGroup[] }) => {
          state.deviceGroups = payload;

          if (!payload.length) {
            state.selectedDeviceGroup = null;
            state.isGlobal = false;
            state.isConfigGroup = false;
            state.hasChanges = false;
            return;
          }

          if (state.addedGroup) {
            state.selectedDeviceGroup = state.addedGroup;
            state.isGlobal = state.addedGroup.isGlobal ?? false;
            state.isConfigGroup = state.addedGroup.isConfigGroup ?? false;
            state.selectedAvailableDevices = [];
            state.selectedGroupDevices = [];
            state.addedGroup = null;
            state.hasChanges = false;
            return;
          }

          if (!state.selectedDeviceGroup && payload.length) {
            const selectedDeviceGroup = getSelectedDeviceGroup(state, payload[0]?.id);
            state.selectedDeviceGroup = selectedDeviceGroup;
            state.isGlobal = selectedDeviceGroup?.isGlobal ?? false;
            state.isConfigGroup = selectedDeviceGroup?.isConfigGroup ?? false;
            state.selectedAvailableDevices = [];
            state.selectedGroupDevices = [];
            state.hasChanges = false;
          }
        },
      )
      .addMatcher(
        deviceGroupApi.endpoints.getDeviceGroupDeviceNames.matchFulfilled,
        (state, { payload }: { payload: IMyDeviceName[] }) => {
          state.groupDevices = payload || [];
          state.hasChanges = false;
        },
      )
      .addMatcher(
        userApi.endpoints.getAllMyDevices.matchFulfilled,
        (state, { payload }) => {
          state.availableDevices = payload || [];
          state.hasChanges = false;
          state.availableDevicesLastTimeUpdated = dayjs().toISOString();
        },
      );
  },
});

export default slice.reducer;
