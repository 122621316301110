export enum TDeviceFilterKind {
  All = 1,
  Online,
  DeviceGroup,
  New,
}

export type TDeviceFilterValue = 'All' | 'Online' | 'New' | number;

export const deviceFilterTitleToKind: Record<string, TDeviceFilterKind> = {
  All: TDeviceFilterKind.All,
  Online: TDeviceFilterKind.Online,
  DeviceGroup: TDeviceFilterKind.DeviceGroup,
  New: TDeviceFilterKind.New,
};

export const deviceFilterKindToFilterValue:
Record<TDeviceFilterKind.All, 'All'> & Record<TDeviceFilterKind.Online, 'Online'> & Record<TDeviceFilterKind.New, 'New'> = {
  [TDeviceFilterKind.All]: 'All',
  [TDeviceFilterKind.Online]: 'Online',
  [TDeviceFilterKind.New]: 'New',
};

export enum TRecentlySeenKind {
  NotRecentlySeen,
  RecentlySeen,
  All,
}

export const recentlySeenToLabel: Record<TRecentlySeenKind, string> = {
  [TRecentlySeenKind.RecentlySeen]: 'Recently Seen',
  [TRecentlySeenKind.NotRecentlySeen]: 'Not Recently Seen',
  [TRecentlySeenKind.All]: 'All Devices',
};
